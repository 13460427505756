import React from 'react';
// import classNames from 'classnames';
import { AnimatePresence, motion } from 'framer-motion';

import { BlockGridItem } from './BlockGridItem';
import styles from './BlockGrid.module.scss';

const BlockGrid = ({ children }) => {
  return (
    <motion.div
      layoutTransition={{
        duration: 0.25,
      }}
      className={styles.grid}
    >
      <AnimatePresence>{children}</AnimatePresence>
    </motion.div>
  );
};

export * from './BlockGridUtils';
export { BlockGrid, BlockGridItem };
