import React, { useCallback, useState } from 'react';
// import useDimensions from 'react-use-dimensions';
import loadable from '@loadable/component';
import classNames from 'classnames';

import { PaperCanvas } from '../PaperCanvas/PaperCanvas';

import styles from './Blobs.module.scss';

const Sketch = loadable.lib(() => import('./src/blobSketch'), { ssr: false });

const Blobs = ({ className, title, subtitle, image, selected = false, ...props }) => {
  const [show, setShow] = useState(false);

  const onPaperSetup = useCallback(
    (paper, canvas, onSetup) => {
      onSetup(paper, canvas, {
        title,
        subtitle,
        image,
        selected,
        onShow: () => setShow(true),
      });
    },
    [subtitle, title, image],
  );

  const cls = classNames(styles.canvas, { [styles.show]: show });

  return (
    <Sketch>
      {sketch => (
        <PaperCanvas
          className={styles.container}
          onFrame={sketch.onFrame}
          onLoad={(paper, canvas) => onPaperSetup(paper, canvas, sketch.onSetup)}
          onResize={sketch.onResize}
          onMouseMove={sketch.onMouseMove}
          {...props}
        >
          {ref => (
            <>
              <h1 className="visually-hidden">{title}</h1>
              <canvas className={cls} ref={ref} resize="true" />
            </>
          )}
        </PaperCanvas>
      )}
    </Sketch>
  );
};

export { Blobs };
