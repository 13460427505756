/* eslint-disable no-unused-vars */
import React, { useState, useEffect, useRef, useCallback } from 'react';
import classNames from 'classnames';

import { studentIndex } from '../../../utils/search';
import { useRavensbourneContext } from '../../context/RavensbourneContext';
import { OverlayPanel } from '../Menu/OverlayPanel';

import ResultsList from './ResultsList';
import styles from './Search.module.scss';
import { useTypedSearch } from './SearchUtils';

const TEXT_MIN_SEARCH = 2;

const Search = () => {
  // Ref for the actual search input
  const searchRef = useRef(null);

  // This is a debounce timer for searching
  const timerRef = useRef(null);

  // Stores the search query
  const [text, setText] = useState('');
  const textRef = useRef('');

  const { searchOpen, setSearchOpen } = useRavensbourneContext();

  // This is a hook to extrapolate the type to search functions
  const { resetTypedText, disableTypedText } = useTypedSearch({
    onUpdateText: text => {
      setText(text);
      textRef.current = text;
    },
    onTriggerOpen: () => {
      setSearchOpen(true);
    },
  });

  // This is a boolean just to know whether post-transition its
  // open or closed
  const [internalOpened, setInternalOpened] = useState(false);

  // A boolean to tell when a search is being performed
  const [isSearching, setIsSearching] = useState(false);

  // An array of search results
  const [results, setResults] = useState([]);

  /**
   * Callback to actually trigger the search via
   * algolia, using just their js client
   *
   * Sets the boolean isSearching to true for
   * duration of search
   */
  const onSearch = useCallback(() => {
    clearTimeout(timerRef.current);

    if (text.length >= TEXT_MIN_SEARCH) {
      setIsSearching(true);
      studentIndex
        .search(textRef.current)
        .then(({ hits }) => {
          setResults(hits);
        })
        .finally(() => setIsSearching(false));
    }
  }, [text]);

  /**
   * Callback to update search term and to
   * re trigger the key up debounced search
   */
  const onChange = useCallback(
    e => {
      setText(e.target.value);
      textRef.current = e.target.value;

      clearTimeout(timerRef.current);
      timerRef.current = setTimeout(() => {
        onSearch();
      }, 500);
    },
    [onSearch],
  );

  /**
   * Triggers search instantly on 'Enter' press
   */
  const onSubmit = useCallback(
    e => {
      e.preventDefault();
      onSearch();
    },
    [onSearch],
  );

  const onClose = useCallback(() => {
    setSearchOpen(false);
  }, [setSearchOpen]);

  /**
   * Fires after the menu panel has finished opening/closing
   *
   * Sets/resets a bunch of typed text things under the hood
   */
  const onTransitionEnd = useCallback(
    open => {
      if (open) {
        disableTypedText(true);
        resetTypedText();

        setInternalOpened(true);
      } else if (!open) {
        setInternalOpened(false);
        disableTypedText(false);
        setResults([]);
        setText('');
        textRef.current = '';
      }
    },
    [disableTypedText, resetTypedText],
  );

  useEffect(() => {
    if (internalOpened) {
      // This is so it focuses after opening and also after
      // the form has stopped being disabled
      requestAnimationFrame(() => {
        requestAnimationFrame(() => {
          searchRef.current.focus();
        });
      });
    }
  }, [isSearching, internalOpened]);

  const cls = classNames(styles.search);

  return (
    <OverlayPanel
      className={cls}
      contentClassName={styles.content}
      open={searchOpen}
      onAfterTransition={onTransitionEnd}
      onClose={onClose}
    >
      <form onSubmit={onSubmit} disabled={isSearching}>
        <input
          disabled={isSearching}
          placeholder="Search here..."
          type="search"
          ref={searchRef}
          value={text}
          onChange={onChange}
        />
      </form>

      <div className={styles.pool}>{<ResultsList results={results} />}</div>
    </OverlayPanel>
  );
};

export default Search;
