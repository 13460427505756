import React from 'react'; // eslint-disable-line no-unused-vars
import ReactPlayer from 'react-player';

const Wistia = ({ urlUri, onReady }) => {
  // https://home.wistia.com/medias/e4a27b971d
  // https://fast.wistia.net/embed/iframe/qld34r7mia
  // https://platform-3.wistia.com/medias/qld34r7mia

  let srcUrl = urlUri;

  // Allow for url pattern that includes '/embed/iframe/'
  if (urlUri.includes('/embed/iframe/')) {
    const id = urlUri.substring(urlUri.lastIndexOf('/') + 1);
    srcUrl = `https://home.wistia.com/medias/${id}`;
  }

  return (
    <div
      className="rul-embed-platform wistia"
      style={{
        position: 'relative',
        paddingTop: '56.25%',
      }}
    >
      <ReactPlayer
        url={srcUrl}
        config={{
          wistia: {
            options: {
              fullscreenButton: true,
              playerColor: '#000099',
              playBar: true,
            },
          },
        }}
        controls
        playing={false}
        width="100%"
        height="100%"
        style={{
          position: 'absolute',
          top: 0,
          left: 0,
        }}
        onReady={onReady}
      />
    </div>
  );
};

export default Wistia;
