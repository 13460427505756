import React from 'react';
import PropTypes from 'prop-types';
import SanityBlockContent from '@sanity/block-content-to-react';

import BigPageMenu from '../../common/BigPageMenu/BigPageMenu';
import { PageSection } from '../../common/PageSection/PageSection';
import { Hero } from '../../layout/Hero/Hero';
// import { Title } from '../../common/Title/Title';
import { Blobs } from '../../common/Blobs/Blobs';
import { RULEmbed } from '../../common/RULEmbed/RULEmbed';
import { courseImage } from '../../../utils/content';

import RandomStudentFeatureSection from './RandomStudentFeatureSection';

import styles from './Home.module.scss';

const departmentToItem = department => {
  return {
    label: department.node.departmentName,
    subItems: department.node.coursesRefs.map(c => ({
      label: c.courseName,
      to: `/courses/${c.slug.current}`,
    })),
  };
};

const Home = ({ introText, statement, departments, students, features, courses }) => {
  const image = courseImage(courses);

  return (
    <>
      <Hero>
        <Blobs title="The Degree Show" image={image} />
      </Hero>
      <PageSection className={`${styles.section} `} thin>
        <h1 className={`header4 ${styles.introBlock}`}>{introText}</h1>
      </PageSection>
      <PageSection dark padY>
        <BigPageMenu items={departments.map(departmentToItem)} />
      </PageSection>
      <PageSection className={styles.random} padY>
        <RandomStudentFeatureSection students={students} features={features} className={`${styles.features}`} />
      </PageSection>
      <PageSection className={`${styles.section} `} padY dark thin>
        <span className={`header4 ${styles.introBlock}`}>
          <div className={styles.introVcMessage}>A Message from our Vice Chancellor</div>
          <SanityBlockContent blocks={statement} />
          <div className={styles.introVc}>Vice Chancellor</div>
        </span>
      </PageSection>
      <PageSection padY>
        <h2 className={`header4 ${styles.videoTitle}`}>
          <p>A Message from the Class of 2020</p>
        </h2>
        <RULEmbed mediaUrl={'https://vimeo.com/456240655'} />
      </PageSection>
    </>
  );
};

Home.propTypes = {
  introText: PropTypes.string.isRequired,
  departments: PropTypes.array.isRequired,
};

export default Home;
