import React from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';

import styles from './Hero.module.scss';

const Hero = ({ className, contentClassName, children, light = false, half = false }) => {
  const cls = classNames(styles.hero, className, { [styles.light]: light }, { [styles.half]: half });
  const contentCls = classNames(styles.content, contentClassName);

  return (
    <section className={cls}>
      <div className={contentCls}>{children}</div>
    </section>
  );
};

Hero.propTypes = {
  className: PropTypes.string,

  children: PropTypes.node.isRequired,
};

export { Hero };
