import React from 'react';
import Img from 'gatsby-image';
import styles from './RULImage.module.scss';

const RULImage = ({ image, alt }) => {
  const percentageRatio = (1 / image?.asset?.metadata?.dimensions?.aspectRatio) * 100;
  let hiddenStyle = {
    width: '100%',
    paddingBottom: `${percentageRatio}%`,
  };
  return image?.asset?.extension === 'gif' ? (
    <div className={styles.gifWrapper}>
      <div aria-hidden={true} style={hiddenStyle}></div>
      <img src={image.asset.url} alt={alt} />
    </div>
  ) : (
    <Img fluid={image.asset?.fluid} alt={alt} />
  );
};

export { RULImage };
