import React from 'react';
import { motion, AnimatePresence } from 'framer-motion';
import { useRavensbourneContext } from '../../context/RavensbourneContext';

import styles from './ServiceWorker.module.scss';

const ServiceWorkerRefresh = () => {
  const { swUpdated } = useRavensbourneContext();

  const variants = {
    initial: {
      opacity: 0,
    },
    enter: {
      transition: {
        delay: 1,
      },
      opacity: 1,
    },
  };
  const onClick = () => window.location.reload();

  return (
    <AnimatePresence>
      {swUpdated && (
        <motion.div
          variants={variants}
          initial="initial"
          animate={'enter'}
          role="button"
          className={styles.serviceWorker}
          onClick={onClick}
        >
          <p>There is a new version of the site. Please click here or refresh the page</p>
          <button className={styles.serviceWorkerButton} onClick={onClick}>
            Reload
          </button>
        </motion.div>
      )}
    </AnimatePresence>
  );
};

export { ServiceWorkerRefresh };
