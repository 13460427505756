import React from 'react';
import { Link } from 'gatsby';

import { useSpecialCursor } from '../../common/SpecialCursor/SpecialCursor';
import styles from './Search.module.scss';

const getLink = hit => {
  switch (hit.search.type) {
    case 'student':
      return `/students/${hit.slug.current}`;
    case 'feature':
      return `/features/${hit.slug.current}`;
    default:
      return null;
  }
};

function Snippets({ hit }) {
  let tags = [];
  let course = '';
  let description = '';

  Object.entries(hit._snippetResult).map(snippet => {
    // dont return a result if the match is none,
    // or the student/feature name as thats done above

    if (!isNaN(snippet[0])) {
      if (snippet[1].matchLevel !== 'none') {
        tags.push(snippet[1].value);
      }
    } else if (snippet[0] === 'course') {
      course = snippet[1].value;
    } else if (snippet[0] === 'description') {
      if (snippet[1].matchLevel !== 'none') {
        description = snippet[1].value;
      }
    }
    return null;
  });

  if (tags.length > 0 || course !== '' || description !== '') {
    return (
      <>
        {course && (
          <p>
            Course: <span dangerouslySetInnerHTML={{ __html: course }} />
          </p>
        )}
        {description && (
          <p>
            {hit.search.type === 'student' ? 'Bio: ' : 'Description: '}
            <span dangerouslySetInnerHTML={{ __html: description }} />
          </p>
        )}
        {tags.length > 0 && (
          <p>
            {'Tags: '}
            {tags.map((tag, index) => (
              <span className={styles.seperator} key={`separator-${index}`}>
                <span dangerouslySetInnerHTML={{ __html: tag }} />
              </span>
            ))}
          </p>
        )}
      </>
    );
  } else {
    return null;
  }
}

const ResultsList = ({ results }) => {
  const { handlers } = useSpecialCursor();

  if (results.length === 0) {
    return null;
  }

  // filter all courses to get unique
  const uniqueCourses = results
    .filter(function (a) {
      var key = a.course;
      if (!this[key]) {
        this[key] = true;
        return true;
      } else {
        return null;
      }
    }, Object.create(null))
    .map(result => result.courseRef);

  const limitedCourses = uniqueCourses.slice(0, 9);

  // get unique tags
  // map through tehm all and then flatten array
  // filter for unique
  // limit tags to 10
  const uniqueTags = results
    .map(result => {
      // if (!result.tag) return;
      if (result.tags) {
        return result.tags.map(tag => {
          return tag;
        });
      } else {
        return null;
      }
    })
    .flat()
    .filter(function (a) {
      var key = a.tagName;
      if (!this[key]) {
        this[key] = true;
        return true;
      } else {
        return null;
      }
    }, Object.create(null));

  const limitedTags = uniqueTags.slice(0, 9);

  return (
    <>
      <header>
        <div>
          <h2>Courses:</h2>
        </div>
      </header>
      <div className={styles.contextualResults}>
        {limitedCourses.map((course, index) => {
          return (
            <span className={styles.seperator} key={`separator-${index}`}>
              <Link className="header5" to={`/courses/${course.slug.current}`}>
                {course.courseName}
              </Link>
            </span>
          );
        })}
        {uniqueCourses.length > limitedCourses.length && ` …`}
      </div>

      <header>
        <div>
          <h2>Tags:</h2>
        </div>
      </header>
      <div className={styles.contextualResults}>
        {limitedTags.map((tag, index) => {
          return (
            <span className={styles.seperator} key={`separator-${index}`}>
              <Link className="header5" to={`/tags/${tag.slug.current}`}>
                {tag.tagName}
              </Link>
            </span>
          );
        })}
        {uniqueTags.length > limitedTags.length && ` …`}
      </div>

      <header>
        <div>
          <h2>{results.length > 50 ? 'More than 50' : results.length} Results:</h2>
        </div>
      </header>
      <ul className={styles.resultsList}>
        {results.map((hit, index) => {
          return (
            <li key={index} className={styles.result}>
              <Link key={hit.id} to={getLink(hit)} className="link" {...handlers}>
                <h3 className="header4 stroke" dangerouslySetInnerHTML={{ __html: hit._highlightResult.name.value }} />
                <Snippets hit={hit} />
              </Link>
            </li>
          );
        })}
      </ul>
    </>
  );
};

export default ResultsList;
