import React from 'react'; // eslint-disable-line no-unused-vars
import { graphql } from 'gatsby';
import SEO from '../components/common/seo';
import StudentPage from '../components/pageComponents/Student/StudentPage';
import { HeaderPad } from '../components/layout/Header/Header';

export const query = graphql`
  query StudentTemplateQuery($slug: String!) {
    student: sanityRulStudent(slug: { current: { eq: $slug } }) {
      courseRef {
        courseName
        courseType
        slug {
          current
        }
      }
      displayName
      featuredImage {
        altText
        image {
          asset {
            fluid(maxWidth: 1920) {
              ...GatsbySanityImageFluid
            }
          }
        }
      }
      isFeaturdImageExplicit
      _rawBio(resolveReferences: { maxDepth: 10 })
      bio {
        children {
          text
        }
      }
      email
      websiteLink {
        linkName
        url
      }
      socialLinks {
        linkName
        socialPlatform
        url
      }
      works {
        isWorkExplicit
        title
        useTitle
        description
        mediaItem {
          hostedMedia {
            mediaUrl
            title
          }
          image {
            altText
            image {
              asset {
                extension
                url
                metadata {
                  dimensions {
                    aspectRatio
                  }
                }
                fluid(maxWidth: 1920) {
                  ...GatsbySanityImageFluid
                  aspectRatio
                }
              }
            }
          }
        }
      }
      tags {
        tagName
        slug {
          current
        }
      }
    }
  }
`;

const StudentTemplate = ({ data, pageContext }) => {
  const student = data.student;
  const seoImage = student.featuredImage?.image?.asset?.fluid?.src;
  const bio = student.bio ? student.bio[0]?.children[0]?.text.substr(0, 140) : '';

  return (
    <>
      <SEO title={student.displayName} description={bio} image={seoImage} />

      <HeaderPad />

      <StudentPage {...data.student} pagination={pageContext.pagination} />
    </>
  );
};

export default StudentTemplate;
