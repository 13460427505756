import { useEffect, useRef } from 'react';

const isValidSearchKey = key => {
  return key.length === 1 && key.match(/^[a-z0-9]+$/i);
};

const useTypedSearch = ({ onUpdateText, onTriggerOpen }) => {
  const intermediateText = useRef({
    active: false,
    text: '',
    disabled: false,
  });

  useEffect(() => {
    const cb = e => {
      if (
        intermediateText.current.disabled ||
        e.target.tagName === 'INPUT' ||
        e.target.tagName === 'TEXTAREA' ||
        e.metaKey ||
        e.ctrlKey
      ) {
        return;
      }

      if (isValidSearchKey(e.key) && !intermediateText.current.active) {
        // intermediate text can capture the text typed while transitioning
        intermediateText.current.active = true;
        intermediateText.current.text = e.key;

        onUpdateText(e.key);
        onTriggerOpen();
      } else if (isValidSearchKey(e.key) && intermediateText.current.active) {
        // if we are currently opening but still typing, store it until we are
        // actually focused in the input
        intermediateText.current.text += e.key;
        onUpdateText(intermediateText.current.text);
      }
    };

    document.addEventListener('keyup', cb);

    return () => document.removeEventListener('keyup', cb);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const resetTypedText = () => {
    intermediateText.current.active = false;
    intermediateText.current.text = '';
  };

  const disableTypedText = toggle => {
    intermediateText.current.disabled = toggle;
  };

  return { resetTypedText, disableTypedText };
};

export { useTypedSearch };
