import React from 'react';
import { Link } from 'gatsby';
import classNames from 'classnames';
import Obfuscate from 'react-obfuscate';
import { useSpecialCursor } from '../SpecialCursor/SpecialCursor';
import { useRavensbourneContext } from '../../context/RavensbourneContext';

const InternalExternalLink = ({ to, label, className, children, direction, ...rest }) => {
  const { setAnimationMode } = useRavensbourneContext();
  const { handlers } = useSpecialCursor();

  const cls = classNames('link', className);

  const clickHandler = direction => {
    if (direction) setAnimationMode(direction);
  };

  if (!to) {
    return null;
  }
  if (to?.startsWith('http')) {
    return (
      <a className={cls} href={to} target="_blank" rel="noreferrer noopener" {...handlers}>
        {children}
      </a>
    );
  }
  if (label === 'Email') {
    return (
      <Obfuscate className={cls} email={to} {...handlers}>
        {label}
      </Obfuscate>
    );
  }

  return (
    <Link
      className={cls}
      to={to}
      {...handlers}
      onClick={() => {
        clickHandler(direction);
      }}
    >
      {children}
    </Link>
  );
};

export { InternalExternalLink };
