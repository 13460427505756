import React from 'react';
import PropTypes from 'prop-types';
import { Link } from 'gatsby';
import classNames from 'classnames';
import { useSpecialCursor } from '../../common/SpecialCursor/SpecialCursor';
import { Icon } from '../../common/Icon/Icon';
import { useRavensbourneContext } from '../../context/RavensbourneContext';
import styles from './Breadcrumb.module.scss';

const Breadcrumb = ({ slug, title, light, direction, className }) => {
  const { setAnimationMode } = useRavensbourneContext();
  const { handlers } = useSpecialCursor();
  const cls = classNames(styles.breadcrumb, { [styles.light]: light }, className);

  const clickHandler = direction => {
    if (direction) setAnimationMode(direction);
  };

  return (
    <div className={cls}>
      <Link
        {...handlers}
        className="link no-underline paragraph heading small"
        to={slug}
        onClick={() => {
          clickHandler(direction);
        }}
      >
        <Icon className={styles.breadcrumbIcon} name="thin-arrow" />
        {title}
      </Link>
    </div>
  );
};

Breadcrumb.propTypes = {
  slug: PropTypes.string.isRequired,
  title: PropTypes.string.isRequired,
};

export { Breadcrumb };
