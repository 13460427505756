import React from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';

import styles from './MainTextContent.module.scss';

const MainTextContent = ({ id, rightSlot, title, subtitle, children, dark = true, className }) => {
  const cls = classNames(
    styles.wrapper,
    {
      [styles.dark]: dark,
    },
    {
      [styles.withTitle]: !!title,
    },
    {
      [styles.withSubtitle]: !!subtitle,
    },
    className,
  );

  return (
    <section className={cls} id={id}>
      {title && (
        <div className={styles.title}>
          <h1 className="header4">{title}</h1>

          {subtitle && <p className="header4 stroke">{subtitle}</p>}
        </div>
      )}
      <div className={`${(styles.content, styles.leftContent)} paragraph`}>{children}</div>
      <div className={styles.rightContent}>{rightSlot}</div>
    </section>
  );
};

MainTextContent.propTypes = {
  id: PropTypes.string,
  rightSlot: PropTypes.node,
  children: PropTypes.node.isRequired,
  dark: PropTypes.bool,
  className: PropTypes.string,
};

export { MainTextContent };
