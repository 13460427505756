import React from 'react';
import SEO from '../components/common/seo';
import Thankyou from '../components/pageComponents/Contact/Thankyou';

const ThankyouPage = () => {
  return (
    <>
      <SEO title="Thank you" />
      <Thankyou />
    </>
  );
};
export default ThankyouPage;
