import React, { useEffect } from 'react'; // eslint-disable-line no-unused-vars

const Sirv = ({ urlUri, onReady }) => {
  useEffect(() => {
    const hasScript = document.getElementById('sirvscript');

    const sirvLoad = () => {
      requestAnimationFrame(() => {
        requestAnimationFrame(() => {
          onReady();
        });
      });
    };

    if (!hasScript) {
      const script = document.createElement('script');

      script.id = 'sirvscript';
      script.src = 'https://scripts.sirv.com/sirv.js';
      script.async = true;
      script.onload = () => {
        sirvLoad();
      };

      document.body.appendChild(script);
    } else {
      setTimeout(() => {
        sirvLoad();
      }, 500);
    }
  }, [onReady]);

  return (
    <>
      <div style={{ width: 'auto', height: '611px' }} className="rul-embed-platform sirv">
        <div className="Sirv" data-src={urlUri}></div>
      </div>
    </>
  );
};

export default Sirv;
