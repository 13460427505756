import React from 'react';
import PropTypes from 'prop-types';

import { InternalExternalLink } from '../../common/InternalExternalLink/InternalExternalLink';
import { useSpecialCursor } from '../../common/SpecialCursor/SpecialCursor';
import { Icon } from '../../common/Icon/Icon';
import { ANIMATION_MODE } from '../../context/RavensbourneContext';

import styles from './Pagination.module.scss';

const Pagination = ({
  tagName: TagName = 'div',
  linkSuffix = '',
  previousSlug,
  nextSlug,
  previousPrefix = 'Previous',
  nextPrefix = 'Next',
  dark,
}) => {
  const { handlers } = useSpecialCursor();
  return (
    <TagName className={styles.pagination}>
      {previousSlug && (
        <InternalExternalLink
          to={previousSlug}
          className={`link paragraph small no-underline ${styles.link} ${styles.linkLeft} ${[styles.dark] && dark}`}
          direction={ANIMATION_MODE.RIGHT}
          {...handlers}
        >
          <Icon name="thin-arrow" />
          {previousPrefix} {linkSuffix}
        </InternalExternalLink>
      )}

      {nextSlug && (
        <InternalExternalLink
          to={nextSlug}
          className={`link paragraph small no-underline ${styles.link} ${styles.linkRight} ${[styles.dark] && dark}`}
          direction={ANIMATION_MODE.LEFT}
          {...handlers}
        >
          {nextPrefix} {linkSuffix}
          <Icon name="thin-arrow" flip />
        </InternalExternalLink>
      )}
    </TagName>
  );
};

Pagination.propTypes = {
  tagName: PropTypes.string,
  linkSuffix: PropTypes.string,
  previousSlug: PropTypes.oneOfType([PropTypes.string, PropTypes.bool]),
  nextSlug: PropTypes.oneOfType([PropTypes.string, PropTypes.bool]),
  nextPrefix: PropTypes.string,
  previousPrefix: PropTypes.string,
};

export { Pagination };
