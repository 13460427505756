import React from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';

import { useSpecialCursor } from '../SpecialCursor/SpecialCursor';
import { iconKeys, Icon, iconSizes } from './Icon';

import styles from './IconButton.module.scss';

const IconButton = ({ icon, size = 'medium', label, className, iconProps = {}, ...props }) => {
  const { onCursorHighlight, onCursorReset } = useSpecialCursor();
  const cls = classNames(styles.button, className);

  return (
    <button
      type="button"
      aria-label={label}
      className={cls}
      onMouseEnter={() => onCursorHighlight()}
      onMouseLeave={() => onCursorReset()}
      {...props}
    >
      <Icon name={icon} size={size} {...iconProps} />
    </button>
  );
};

IconButton.propTypes = {
  icon: PropTypes.oneOf(iconKeys).isRequired,
  size: PropTypes.oneOf(iconSizes),
  iconProps: PropTypes.object,
  className: PropTypes.string,

  /** Because an icon button isn't very descriptive, this gives it a label */
  label: PropTypes.string.isRequired,
};

export { IconButton };
