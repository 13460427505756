import React from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';

import { ReactComponent as SearchIcon } from './icons/search.svg';
import { ReactComponent as MenuIcon } from './icons/menu.svg';
import { ReactComponent as XIcon } from './icons/x.svg';
import { ReactComponent as Arrow } from './icons/arrow.svg';
import { ReactComponent as ThinArrow } from './icons/thin-arrow.svg';

import styles from './Icon.module.scss';

const iconMap = {
  search: SearchIcon,
  menu: MenuIcon,
  x: XIcon,
  arrow: Arrow,
  'thin-arrow': ThinArrow,
};

export const iconKeys = Object.keys(iconMap);
export const iconSizes = ['small', 'medium', 'large'];

const Icon = ({ name, size = 'medium', className, flip, ...props }) => {
  const cls = classNames(styles.icon, styles[size], { [styles.flip]: flip }, className);

  // Retrieve the icon from our icon object
  // CamelCase variable tells jsx itll be a component
  const El = iconMap[name];

  // If for whatever reason the name passed doesn't
  // exist, eject the icon to not error
  if (!El) {
    return null;
  }

  return (
    <span className={cls} {...props}>
      <El />
    </span>
  );
};

Icon.propTypes = {
  name: PropTypes.oneOf(iconKeys).isRequired,
  size: PropTypes.oneOf(iconSizes),
  className: PropTypes.string,
};

export { Icon };
