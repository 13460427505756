import React from 'react';
import { graphql, Link } from 'gatsby';

import SEO from '../components/common/seo';
import { HeaderPad } from '../components/layout/Header/Header';
import { PageSection } from '../components/common/PageSection/PageSection';
import { Hero } from '../components/layout/Hero/Hero';
import { Blobs } from '../components/common/Blobs/Blobs';
import { courseImage } from '../utils/content';
import { useRavensbourneContext } from '../components/context/RavensbourneContext';

export const query = graphql`
  query NotFoundPageQuery {
    courses: allSanityCourse {
      edges {
        node {
          blobImages {
            image {
              asset {
                fluid {
                  src
                }
              }
            }
          }
        }
      }
    }
  }
`;

const NotFoundPage = ({ data }) => {
  const courses = data.courses.edges;
  const image = courseImage(courses);

  const { setSearchOpen } = useRavensbourneContext();

  const onSearchClick = e => {
    e.preventDefault();
    setSearchOpen(true);
  };

  return (
    <>
      <SEO title="404: Not found" />

      <HeaderPad />

      <Hero half>
        <Blobs title="Not found" image={image} />
      </Hero>

      <PageSection className="section-404" padY>
        <p>There is no page at this URL.</p>
        <p>
          Use the{' '}
          <button className="link" onClick={onSearchClick}>
            search
          </button>{' '}
          or start back at the <Link to="/">home page</Link>.
        </p>
      </PageSection>
    </>
  );
};

export default NotFoundPage;
