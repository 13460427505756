import React from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';

import { useSpecialCursor } from '../SpecialCursor/SpecialCursor';
import { InternalExternalLink } from '../InternalExternalLink/InternalExternalLink';

import styles from './BigLink.module.scss';

const sizes = {
  small: 'header4',
  normal: 'header3',
  large: 'header2',
};

const BigLink = ({
  to,
  onClick,
  children,
  size = 'large',
  stroke = false,
  onMouseEnter,
  onMouseLeave,
  className,
  direction,
  ...props
}) => {
  const { onCursorHighlight, onCursorReset } = useSpecialCursor();

  const onEnter = e => {
    if (onMouseEnter) {
      onMouseEnter(e);
    }

    onCursorHighlight();
  };

  const onLeave = e => {
    if (onMouseLeave) {
      onMouseLeave(e);
    }

    onCursorReset();
  };

  const cls = classNames(styles.link, { [styles.stroke]: stroke }, sizes[size], className);

  if (to) {
    return (
      <InternalExternalLink className={cls} to={to} direction={direction}>
        {children}
      </InternalExternalLink>
    );
  }

  return (
    <button className={cls} onClick={onClick} onMouseEnter={onEnter} onMouseLeave={onLeave} {...props}>
      {children}
    </button>
  );
};

BigLink.propTypes = {
  to: PropTypes.string,
  onClick: PropTypes.func,
  children: PropTypes.node.isRequired,
  className: PropTypes.string,
  size: PropTypes.oneOf(['small', 'normal', 'large']),
  stroke: PropTypes.bool,
};

export { BigLink };
