import React from 'react';
// import PropTypes from 'prop-types';
import Img from 'gatsby-image';

import styles from './CoverImage.module.scss';

const CoverImage = ({ image, alt, aspectRatio = 1.4 }) => {
  return (
    <section className={styles.cover}>
      <Img fluid={{ ...image, aspectRatio }} alt={alt} />
    </section>
  );
};

CoverImage.propTypes = {};

export { CoverImage };
