import React, { useState, useMemo, useCallback } from 'react';
import classNames from 'classnames';
import { BlockGridItem, BlockGrid, mergeTiles } from '../../common/BlockGrid/BlockGrid';
import { useSpecialCursor } from '../../common/SpecialCursor/SpecialCursor';
import { shuffle } from '../../../utils/common';
import { BigLink } from '../../common/BigLink/BigLink';
// import { Icon } from '../../common/Icon/Icon';

import { ANIMATION_MODE } from '../../context/RavensbourneContext';
import styles from './StudentGrid.module.scss';
import { StudentGridFilter } from './StudentGridFilter';

const VIEW_MODE = {
  LIST: 'list',
  GRID: 'grid',
};

function StudentGrid({ students, featureProjects, courseTags, specialism = true }) {
  const [currentTag, setTag] = useState(false);
  const [viewMode, setViewMode] = useState(VIEW_MODE.GRID);
  const [dropdown, setDropdown] = useState(true);
  const { handlers } = useSpecialCursor();

  const itemCards = useMemo(() => {
    return mergeTiles(
      shuffle(
        students.map(({ node: student }) => {
          return {
            name: student.displayName,
            size: 'small',
            type: 'student',
            slug: `/students/${student.slug?.current}`,
            coverImage: student.featuredImage,
            tags: student.tags,
            course: student.courseRef?.courseName,
          };
        }),
      ),
      featureProjects.map(project => {
        return {
          name: project.displayTitle,
          size: 'large',
          type: 'feature',
          slug: `/features/${project.slug?.current}`,
          coverImage: project.coverImage,
          tags: project.tags,
          course: project.courseRef?.courseName,
        };
      }),
      9,
    );
  }, [featureProjects, students]);

  const filteredCards = useMemo(() => {
    return itemCards.filter(card => {
      if (!currentTag) {
        return true;
      }

      return card.tags.some(tag => tag.slug.current === currentTag);
    });
  }, [itemCards, currentTag]);

  const itemList = useMemo(() => {
    return {
      students: students.map(({ node: student }) => {
        return {
          name: student.displayName,
          slug: `/students/${student.slug?.current}`,
          coverImage: student.featuredImage,
          tags: student.tags,
          course: student.courseRef?.courseName,
        };
      }),
      featured: featureProjects.map(project => {
        return {
          name: project.displayTitle,
          slug: `/features/${project.slug?.current}`,
          coverImage: project.coverImage,
          tags: project.tags,
          course: project.courseRef?.courseName,
        };
      }),
    };
  }, [featureProjects, students]);

  const filteredList = useMemo(() => {
    const fil = items =>
      items.filter(item => {
        if (!currentTag) {
          return true;
        }

        return item.tags.some(tag => tag.slug.current === currentTag);
      });
    return {
      students: fil(itemList.students),
      featured: fil(itemList.featured),
    };
  }, [itemList, currentTag]);

  const onToggleFilter = useCallback(
    newTag => {
      if (currentTag === newTag) {
        setTag(false);
      } else {
        setTag(newTag);
      }
    },
    [currentTag],
  );

  const sortedCourseTags = courseTags?.sort(function (a, b) {
    var textA = a.tagName.toUpperCase();
    var textB = b.tagName.toUpperCase();
    return textA < textB ? -1 : textA > textB ? 1 : 0;
  });

  const onToggleDropdown = useCallback(
    force => {
      setDropdown(typeof force === 'undefined' ? !dropdown : force);
    },
    [dropdown],
  );

  const changeView = useCallback(
    force => {
      if (force && typeof force === 'string') {
        setViewMode(force);
        return;
      }
      onToggleFilter(false);
      onToggleDropdown();
      setViewMode(viewMode === VIEW_MODE.GRID ? VIEW_MODE.LIST : VIEW_MODE.GRID);
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [viewMode],
  );

  const optionCls = classNames(styles.options, { [styles.specialism]: specialism });

  return (
    <div className={styles.wrapper}>
      <div className={optionCls}>
        {/* {specialism && (
          <button {...handlers} className={`${styles.optionsButton} headline`} onClick={() => onToggleDropdown()}>
            Filter by Tag {dropdown && <Icon name="x" size="small" />}
          </button>
        )} */}
        {specialism && (
          <div className={styles.optionsDropdown}>
            {/* Tags */}
            {sortedCourseTags?.length > 0 && (
              <>
                <StudentGridFilter open={dropdown} onClose={() => setDropdown(false)}>
                  {sortedCourseTags.map((tag, index) => {
                    const cls = classNames(
                      styles.filter,
                      // 'header4',
                      {
                        [styles.active]: tag.slug.current === currentTag,
                      },
                      {
                        stroke: tag.slug.current === currentTag,
                      },
                    );

                    return (
                      <span className={styles.seperator} key={tag.tagName}>
                        <button {...handlers} onClick={e => onToggleFilter(tag.slug.current)} className={cls}>
                          {tag.tagName}
                        </button>
                      </span>
                    );
                  })}

                  {currentTag && (
                    <span className={styles.seperator}>
                      <button
                        {...handlers}
                        onClick={e => onToggleFilter(false)}
                        className={`${styles.filter} ${styles.filterReset}`}
                      >
                        Reset
                      </button>
                    </span>
                  )}
                </StudentGridFilter>
              </>
            )}
          </div>
        )}
        <button className={`${styles.optionsButton} ${styles.listButton}`} onClick={() => changeView()}>
          {viewMode === VIEW_MODE.GRID ? 'List View' : 'Grid View'}
          {/* {viewMode === VIEW_MODE.GRID ? '☰ List View' : '☷ Grid View'} */}
        </button>
        {/* <div className={styles.optionsToggle}>
          
        </div> */}
      </div>

      {viewMode === VIEW_MODE.GRID ? (
        <BlockGrid>
          {filteredCards.map((card, index) => {
            return (
              <BlockGridItem
                key={`card-${card.name}-${index}`}
                large={card.type === 'feature'}
                to={card.slug}
                image={card.coverImage?.image}
                title={card.name}
                imageAlt={card.coverImage?.altText ? card.coverImage?.altText : card.name}
                subtitle={!specialism && card.course}
                direction={ANIMATION_MODE.LEFT}
                index={index}
              />
            );
          })}
        </BlockGrid>
      ) : (
        <>
          {filteredList.students.length > 0 && (
            <ul className={styles.list}>
              <li className={styles.listTitle}>Student profiles</li>
              {filteredList.students.map(item => (
                <li className={styles.listItem} key={item.slug}>
                  <BigLink size="normal" stroke to={item.slug} direction={ANIMATION_MODE.LEFT}>
                    {item.name}
                  </BigLink>
                </li>
              ))}
            </ul>
          )}
          {filteredList.featured.length > 0 && (
            <ul className={styles.list}>
              <li className={styles.listTitle}>Feature projects</li>
              {filteredList.featured.map(item => (
                <li className={styles.listItem} key={item.slug}>
                  <BigLink size="normal" stroke to={item.slug} direction={ANIMATION_MODE.LEFT}>
                    {item.name}
                  </BigLink>
                </li>
              ))}
            </ul>
          )}
        </>
      )}
    </div>
  );
}

export default StudentGrid;
