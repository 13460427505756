import React, { useMemo } from 'react';
import SanityBlockContent from '@sanity/block-content-to-react';
import { PageSection } from '../../common/PageSection/PageSection';
import { MainTextContent } from '../../layout/MainTextContent/MainTextContent';
import { PageBlock } from '../../layout/PageBlocks/PageBlock';
import { blocksToContent } from '../../../utils/content';

const PostPage = ({ description, coverImage, displayTitle, pageBlocks, _rawIntroduction }) => {
  /** turn the internal array of blocks into one flat array to iterate over */
  const splitBlocks = useMemo(() => {
    return blocksToContent(pageBlocks);
  }, [pageBlocks]);

  return (
    <>
      <PageSection dark>
        <MainTextContent
          title={displayTitle}
          // subtitle={featureType?.categoryName}
          // rightSlot={<>{tagLinks.length > 0 && <LinkList title="Tags" links={tagLinks} />}</>}
        >
          <SanityBlockContent blocks={_rawIntroduction} />
        </MainTextContent>
      </PageSection>

      <PageSection padY>
        {splitBlocks.map(({ content, sanityContent, ...rest }, idx) => (
          <PageBlock key={idx} {...rest}>
            {content && <div>{content}</div>}
            {sanityContent && <SanityBlockContent blocks={sanityContent} />}
          </PageBlock>
        ))}
      </PageSection>
    </>
  );
};

export { PostPage };
