import React, { useMemo } from 'react';
import SanityBlockContent from '@sanity/block-content-to-react';

import { PageSection } from '../../common/PageSection/PageSection';
import { Breadcrumb } from '../../layout/Breadcrumb/Breadcrumb';
import { MainTextContent } from '../../layout/MainTextContent/MainTextContent';
import { LinkList } from '../../layout/LinkList/LinkList';
import { Pagination } from '../../layout/Pagination/Pagination';
import { PageBlock } from '../../layout/PageBlocks/PageBlock';
import { ANIMATION_MODE } from '../../context/RavensbourneContext';

import styles from './StudentPage.module.scss';

const StudentPage = ({ courseRef, email, socialLinks, websiteLink, tags, displayName, _rawBio, works, pagination }) => {
  const courseLink = `/courses/${courseRef.slug.current}`;
  const courseName = `${courseRef.courseType ? `${courseRef.courseType} ` : ''}${courseRef.courseName}`;

  const links = useMemo(() => {
    const arr = [];

    if (email) {
      arr.push({
        // label: email,
        label: 'Email',
        to: email,
      });
    }

    if (socialLinks.length) {
      socialLinks.forEach(link => arr.push({ to: link.url, label: link.socialPlatform }));
    }

    if (websiteLink) {
      arr.push({
        to: websiteLink.url,
        label: 'Website',
        // label: websiteLink.linkName,
      });
    }

    return arr;
  }, [socialLinks, websiteLink, email]);

  const tagLinks = useMemo(() => {
    return tags.map(tag => {
      return {
        to: `/tags/${tag.slug.current}`,
        label: tag.tagName,
      };
    });
  }, [tags]);

  return (
    <>
      <PageSection>
        <Breadcrumb slug={courseLink} title={courseName} direction={ANIMATION_MODE.RIGHT} light />
      </PageSection>

      <PageSection dark>
        <MainTextContent
          title={displayName}
          rightSlot={
            <>
              {links.length > 0 && <LinkList title="Links" links={links} />}
              {tagLinks.length > 0 && <LinkList title="Tags" links={tagLinks} />}
            </>
          }
        >
          <SanityBlockContent blocks={_rawBio} />
        </MainTextContent>
      </PageSection>

      {/* Works */}
      {works?.length > 0 && (
        <PageSection padY>
          {works.map((work, idx) => (
            <PageBlock
              key={`work-${idx}`}
              image={work.mediaItem?.image?.image?.asset?.fluid}
              imageObject={work.mediaItem?.image?.image}
              isPortrait={work.mediaItem?.image?.image?.asset?.fluid.aspectRatio < 1}
              imageAltText={work.mediaItem?.image?.altText ? work.mediaItem?.image?.altText : displayName}
              mediaUrl={work.mediaItem?.hostedMedia?.mediaUrl}
            >
              <div className={styles.breakWord}>
                {work.useTitle && work.title}
                {work.useTitle && work.description && <span> – </span>}
                {work.description}
              </div>
            </PageBlock>
          ))}
        </PageSection>
      )}

      <PageSection dark className={styles.gridBreadcrumbPagination}>
        <Breadcrumb
          slug={courseLink}
          title={courseName}
          direction={ANIMATION_MODE.RIGHT}
          className={styles.gridBreadcrumb}
        />
        <Pagination previousSlug={pagination?.prev} nextSlug={pagination?.next} dark />
      </PageSection>
    </>
  );
};

export default StudentPage;
