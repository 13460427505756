import React, { useState, useCallback } from 'react';
import classNames from 'classnames';
import PropTypes from 'prop-types';
import BigPageMenuItem, { BigPageMenuItemProps } from './BigPageMenuItem';

import styles from './BigPageMenu.module.scss';

const BigPageMenu = ({ items }) => {
  const [openIndex, setOpenIndex] = useState(-1);

  const onInternalToggle = useCallback(
    idx => {
      setOpenIndex(openIndex === idx ? -1 : idx);
    },
    [openIndex],
  );

  return (
    <div className={styles.giantMenuContainer}>
      <ul>
        {items.map((item, i) => {
          const cls = classNames(styles.item, {
            [styles.inactive]: openIndex >= 0 && i !== openIndex,
          });
          return (
            <li className={cls} key={`department-${i}`}>
              <BigPageMenuItem
                {...item}
                whiteOutline={true}
                open={i === openIndex}
                onToggle={() => onInternalToggle(i)}
              />
            </li>
          );
        })}
      </ul>
    </div>
  );
};

BigPageMenu.propTypes = {
  items: PropTypes.arrayOf(PropTypes.shape(BigPageMenuItemProps)).isRequired,
};

export default BigPageMenu;
