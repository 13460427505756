import React, { useMemo } from 'react'; // eslint-disable-line no-unused-vars
import { graphql } from 'gatsby';
import SanityBlockContent from '@sanity/block-content-to-react';
import SEO from '../components/common/seo';
import { PageSection } from '../components/common/PageSection/PageSection';
import { CoverImage } from '../components/layout/CoverImage/CoverImage';
import { MainTextContent } from '../components/layout/MainTextContent/MainTextContent';
import { PageBlock } from '../components/layout/PageBlocks/PageBlock';
import { useSplitContent, blocksToContent } from '../utils/content';
import { HeaderPad } from '../components/layout/Header/Header';
// import PageBlocks from '../components/layout/PageBlocks/PageBlocks';

export const query = graphql`
  query AboutRavensbournePageQuery {
    aboutrul: sanitySingletonAboutRavensbourne {
      title
      description
      coverImage {
        altText
        image {
          asset {
            fluid(maxWidth: 1920) {
              ...GatsbySanityImageFluid
            }
          }
        }
      }
      pageBlocks {
        _rawTextArea(resolveReferences: { maxDepth: 10 })
        imageText {
          imageTextBlock {
            description
            image {
              altText
              image {
                asset {
                  fluid(maxWidth: 1920) {
                    ...GatsbySanityImageFluid
                  }
                }
              }
            }
          }
        }
        mediaText {
          mediaTextBlock {
            description
            media {
              title
              mediaUrl
            }
          }
        }
      }
    }
  }
`;

const AboutRavensbournePage = ({ data }) => {
  const aboutRul = data.aboutrul;
  const seoDescription = aboutRul.description?.substr(0, 140) || '';
  const seoImage = aboutRul.coverImage?.image?.asset?.fluid?.src;

  const [initialText, restOfBlocks] = useSplitContent(aboutRul.pageBlocks);

  /** turn the internal array of blocks into one flat array to iterate over */
  const splitBlocks = useMemo(() => {
    return blocksToContent(restOfBlocks);
  }, [restOfBlocks]);

  return (
    <>
      <SEO title={aboutRul.title} description={seoDescription} image={seoImage} />

      <HeaderPad />

      {aboutRul.coverImage?.image?.asset && (
        <CoverImage
          image={aboutRul.coverImage.image.asset.fluid}
          aspectRatio={16 / 8}
          alt={aboutRul.coverImage.image.altText}
        />
      )}

      <PageSection dark padY>
        <MainTextContent title={aboutRul.title} dark={true}>
          {initialText.map((block, idx) => (
            <SanityBlockContent key={idx} blocks={block} />
          ))}
        </MainTextContent>
      </PageSection>

      <PageSection padY>
        {splitBlocks.map(({ content, sanityContent, ...rest }, idx) => (
          <PageBlock key={idx} {...rest}>
            {sanityContent && <SanityBlockContent blocks={sanityContent} />}
            {content && <p>{content}</p>}
          </PageBlock>
        ))}
      </PageSection>
    </>
  );
};
export default AboutRavensbournePage;
