import React, { useState, useCallback, useEffect } from 'react';
import { useStaticQuery, graphql } from 'gatsby';
import classNames from 'classnames';
import useMeasure from 'react-use-measure';
import { ResizeObserver } from '@juggle/resize-observer';
import { useRavensbourneContext } from '../../context/RavensbourneContext';
import { BigLink } from '../../common/BigLink/BigLink';
import BigPageMenuItem from '../../common/BigPageMenu/BigPageMenuItem';
import useWindowDimensions from '../../../utils/interactions';
import { OverlayPanel } from './OverlayPanel';

// import { atRule } from 'postcss';
import styles from './Menu.module.scss';

const menuItems = [
  {
    label: 'About Ravensbourne',
    to: '/aboutravensbourne',
  },
];

const Menu = () => {
  const [openIndex, setOpenIndex] = useState(-1);
  const [ref, bounds] = useMeasure({ polyfill: ResizeObserver });
  const { height: windowHeight } = useWindowDimensions();

  const onToggle = useCallback(
    idx => {
      setOpenIndex(openIndex === idx ? -1 : idx);
    },
    [openIndex],
  );

  const { menuOpen, setMenuOpen } = useRavensbourneContext();

  const data = useStaticQuery(graphql`
    query SiteDepartmentsQuery {
      allSanityDepartment(sort: { order: ASC, fields: departmentName }) {
        edges {
          node {
            departmentName
            coursesRefs {
              courseName
              courseType
              slug {
                current
              }
            }
          }
        }
      }
    }
  `);

  const departmentToItem = department => {
    return {
      label: department.node.departmentName,
      subItems: department.node.coursesRefs.map(c => ({
        label: c.courseName,
        to: `/courses/${c.slug.current}`,
      })),
    };
  };

  const closeMenu = () => {
    setMenuOpen(false);
    onToggle();
  };

  useEffect(() => {
    if (!menuOpen) {
      onToggle(-1);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [menuOpen]);

  const departments = data.allSanityDepartment.edges.map(departmentToItem);

  const overlayCls = classNames(styles.menuContent, {
    [styles.menuCenter]: bounds.height < windowHeight,
  });

  return (
    <OverlayPanel className={styles.menu} contentClassName={overlayCls} open={menuOpen} onClose={() => closeMenu()}>
      <ul className={styles.list} ref={ref}>
        {departments.map((item, i) => {
          const cls = classNames(styles.item, {
            [styles.inactive]: openIndex >= 0 && i !== openIndex,
          });
          return (
            <li className={cls} key={`department-${i}`}>
              <BigPageMenuItem {...item} open={i === openIndex} onToggle={() => onToggle(i)} />
            </li>
          );
        })}
        {menuItems.map(item => (
          <li key={item.to} className={styles.item}>
            <BigLink to={item.to} stroke>
              {item.label}
            </BigLink>
          </li>
        ))}
      </ul>
    </OverlayPanel>
  );
};

export default Menu;
