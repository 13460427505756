import React from 'react'; // eslint-disable-line no-unused-vars
import { graphql } from 'gatsby';

// import Image from "../components/image"
import SEO from '../components/common/seo';
import Home from '../components/pageComponents/Home/Home';

export const query = graphql`
  query HomePageQuery {
    home: sanitySingletonHomepage {
      title
      description
      _rawStatement(resolveReferences: { maxDepth: 10 })
      blobImages {
        altText
        image {
          asset {
            fluid(maxWidth: 1920) {
              ...GatsbySanityImageFluid
            }
          }
        }
      }
    }
    departments: allSanityDepartment(sort: { order: ASC, fields: departmentName }) {
      edges {
        node {
          departmentName
          coursesRefs {
            courseName
            courseType
            slug {
              current
            }
          }
        }
      }
    }
    students: allSanityRulStudent {
      edges {
        node {
          _id
          displayName
          slug {
            current
          }
          featuredImage {
            image {
              asset {
                _id
              }
            }
            altText
          }
          courseRef {
            courseName
          }
        }
      }
    }

    features: allSanityFeatureProject(
      filter: {
        slug: {
          current: {
            nin: [
              "about-learn-about-how-creativelab-developed-the-concept-for-web"
              "about-learn-about-how-the-blobs-were-created"
              "about-the-making-of-the-degree-show"
            ]
          }
        }
      }
    ) {
      edges {
        node {
          _id
          slug {
            current
          }
          displayTitle
          coverImage {
            image {
              asset {
                _id
              }
            }
          }
          courseRef {
            courseName
          }
        }
      }
    }
    courses: allSanityCourse {
      edges {
        node {
          blobImages {
            image {
              asset {
                fluid {
                  src
                }
              }
            }
          }
        }
      }
    }
  }
`;

const IndexPage = ({ data }) => {
  const raveIntroduction = data.home.description;
  const statement = data.home._rawStatement;
  const departments = data.departments.edges;
  const students = data.students.edges;
  const features = data.features.edges;
  const courses = data.courses.edges;

  return (
    <>
      <SEO title="Home" />
      <Home
        introText={raveIntroduction}
        statement={statement}
        departments={departments}
        students={students}
        features={features}
        courses={courses}
      />
    </>
  );
};
export default IndexPage;
