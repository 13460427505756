export const mergeTiles = (small, large, everyN = 4) => {
  const tiles = [];

  let counter = 0;

  while (large.length || small.length) {
    if (counter % everyN === 0 && large.length) {
      const largeTile = large.splice(0, 1);
      tiles.push(...largeTile);
    }

    const smallTile = small.splice(0, 1);
    tiles.push(...smallTile);
    counter++;
  }
  return tiles;
};
