import React from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';

import { InternalExternalLink } from '../../common/InternalExternalLink/InternalExternalLink';
import styles from './LinkList.module.scss';

const LinkList = ({ links, title, className }) => {
  const cls = classNames(styles.list, className);

  return (
    <ul className={cls}>
      {title && (
        <li>
          <p className={`heading ${styles.title}`}>{title}</p>
        </li>
      )}
      {links.map(link => {
        return (
          <li key={link.to}>
            <InternalExternalLink to={link.to} label={link.label}>
              {link.label}
            </InternalExternalLink>
          </li>
        );
      })}
    </ul>
  );
};

LinkList.propTypes = {
  links: PropTypes.arrayOf(
    PropTypes.shape({
      to: PropTypes.string.isRequired,
      label: PropTypes.string.isRequired,
    }),
  ).isRequired,
  title: PropTypes.string,
  className: PropTypes.string,
};

export { LinkList };
