import React from 'react';
import classNames from 'classnames';
import { Link } from 'gatsby';
import Img from 'gatsby-image';
import { motion, useInvertedScale } from 'framer-motion';

import { useSpecialCursor } from '../SpecialCursor/SpecialCursor';
import { useRavensbourneContext } from '../../context/RavensbourneContext';
import styles from './BlockGrid.module.scss';

const variants = {
  initial: {
    // opacity: 0,
    // scale: 0,
    // transition: { duration: 0.25 },
  },
  exit: {
    // opacity: 0,
    // scale: 0.4,
    // transition: { duration: 0.25 },
  },
  enter: {
    // opacity: 1,
    // scale: 1,
  },
};

const BlockGridItem = ({ large, image, imageAlt, imageSrc, title, to, direction, subtitle, index }) => {
  const { setAnimationMode } = useRavensbourneContext();
  const { handlers } = useSpecialCursor();

  const cls = classNames(
    styles.item,
    {
      [styles.large]: large,
    },
    {
      [styles.noImage]: !image,
    },
  );

  const clickHandler = direction => {
    if (direction) setAnimationMode(direction);
  };

  const inverted = useInvertedScale();

  return (
    <motion.div
      key={index}
      layoutTransition={{ duration: 0.08 * index }}
      // layoutTransition={{ duration: 0.25 }}
      initial="initial"
      variants={variants}
      animate="enter"
      exit="exit"
      className={cls}
      style={inverted}
    >
      <Link
        className={styles.link}
        to={to}
        {...handlers}
        onClick={() => {
          clickHandler(direction);
        }}
      >
        {image && (
          <Img alt={imageAlt} fluid={image.asset.fluid} sizes={{ ...image?.asset?.fluid, aspectRatio: 1 / 1 }} />
        )}
        {imageSrc && <img src={imageSrc} alt={imageAlt} />}
        <span className={styles.overlay}>
          <span className="paragraph heading">{title}</span>
          {subtitle && <span className="paragraph heading">{subtitle}</span>}
        </span>
      </Link>
    </motion.div>
  );
};

export { BlockGridItem };
