import React from 'react'; // eslint-disable-line no-unused-vars
import { graphql } from 'gatsby';
import SEO from '../components/common/seo';
import StudentGrid from '../components/pageComponents/Course/StudentGrid';
import { PageSection } from '../components/common/PageSection/PageSection';
// import { Title } from '../components/common/Title/Title';
import { Hero } from '../components/layout/Hero/Hero';
import { Blobs } from '../components/common/Blobs/Blobs';
import defaultBlobImage from '../components/common/Blobs/src/rave.jpg';
import { CourseAbout } from '../components/pageComponents/Course/CourseAbout';

export const query = graphql`
  query CourseTemplateQuery($slug: String!) {
    course: sanityCourse(slug: { current: { eq: $slug } }) {
      courseName
      breakName
      courseType
      courseCode
      description
      blobImages {
        altText
        image {
          asset {
            fluid(maxWidth: 1920) {
              ...GatsbySanityImageFluid
            }
          }
        }
      }
      _rawBody(resolveReferences: { maxDepth: 10 })
      featureProjectRefs {
        slug {
          current
        }
        featureProjectName
        displayTitle
        courseRef {
          courseName
        }
        coverImage {
          altText
          image {
            asset {
              fluid(maxWidth: 960) {
                ...GatsbySanityImageFluid
              }
            }
          }
        }
        tags {
          tagName
          slug {
            current
          }
        }
      }
      tags {
        tagName
        slug {
          current
        }
      }
      websiteLink {
        url
        linkName
      }
      socialLinks {
        linkName
        url
        socialPlatform
      }
    }

    students: allSanityRulStudent(
      filter: { courseRef: { slug: { current: { eq: $slug } } } }
      sort: { fields: displayName, order: ASC }
    ) {
      edges {
        node {
          displayName
          featuredImage {
            altText
            image {
              asset {
                fluid(maxWidth: 400) {
                  ...GatsbySanityImageFluid
                }
              }
            }
          }
          slug {
            current
          }
          courseRef {
            courseName
          }
          tags {
            tagName
            slug {
              current
            }
          }
        }
      }
    }
  }
`;

const CourseTemplate = ({ data, pageContext }) => {
  const course = data.course;
  const seoDescription = course.description?.substr(0, 140) || '';
  const seoImage = course.courseImageCover?.image?.asset?.fluid?.src;

  const students = data.students.edges;
  const featureProjects = data.course.featureProjectRefs;

  const breakName = course.breakName.replace(/\\n/g, '\n');

  const blobImage =
    course.blobImages?.length > 0
      ? course.blobImages[Math.floor(Math.random() * course.blobImages.length)].image?.asset?.fluid?.src
      : defaultBlobImage;

  return (
    <>
      <SEO title={course.courseName} description={seoDescription} image={seoImage} />

      <Hero>
        <Blobs title={breakName} subtitle={course.courseType} image={blobImage} />
      </Hero>

      <div>
        <PageSection>
          <CourseAbout
            title={`${course.courseType} ${course.courseName}`}
            introText={course._rawBody}
            websiteLink={course.websiteLink}
            socialLinks={course.socialLinks}
          />
        </PageSection>
      </div>

      <PageSection dark>
        <StudentGrid students={students} featureProjects={featureProjects} courseTags={pageContext.tags} />
      </PageSection>
    </>
  );
};

export default CourseTemplate;
