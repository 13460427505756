import React from 'react';
import { graphql } from 'gatsby';
import SEO from '../components/common/seo';
import { FeaturePage } from '../components/pageComponents/Feature/FeaturePage';
import { HeaderPad } from '../components/layout/Header/Header';

export const query = graphql`
  query FeatureTemplateQuery($slug: String!) {
    featureProject: sanityFeatureProject(slug: { current: { eq: $slug } }) {
      displayTitle
      description
      _rawIntroduction(resolveReferences: { maxDepth: 10 })
      featureType {
        categoryName
      }
      coverImage {
        altText
        image {
          asset {
            fluid(maxWidth: 1920) {
              ...GatsbySanityImageFluid
              aspectRatio
            }
          }
        }
      }
      pageBlocks {
        _rawTextArea(resolveReferences: { maxDepth: 10 })
        imageText {
          imageTextBlock {
            description
            image {
              altText
              image {
                asset {
                  extension
                  url
                  metadata {
                    dimensions {
                      aspectRatio
                    }
                  }
                  fluid(maxWidth: 1920) {
                    ...GatsbySanityImageFluid
                    aspectRatio
                  }
                }
              }
            }
          }
        }
        mediaText {
          mediaTextBlock {
            description
            media {
              mediaUrl
            }
          }
        }
      }
      courseRef {
        courseName
        slug {
          current
        }
      }
      departmentRef {
        departmentName
        slug {
          current
        }
      }
      tags {
        tagName
        slug {
          current
        }
      }
    }
  }
`;

const FeatureTemplate = ({ data }) => {
  const featureProject = data.featureProject;
  const seoDescription = featureProject.description?.substr(0, 140) || '';
  const seoImage = featureProject.coverImage?.image?.asset?.fluid?.src;

  return (
    <>
      <SEO title={featureProject.displayTitle} description={seoDescription} image={seoImage} />

      <HeaderPad />

      <FeaturePage {...featureProject} />
    </>
  );
};

export default FeatureTemplate;
