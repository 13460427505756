/* eslint-disable no-unused-vars */
import React, { useEffect } from 'react';
import { motion, AnimatePresence } from 'framer-motion';
import { ANIMATION_MODE, useRavensbourneContext } from '../../context/RavensbourneContext';

const Transition = ({ children, location }) => {
  const { animationMode, setAnimationMode } = useRavensbourneContext();

  const duration = 0.25;

  const distance = 30;
  const startOpacity = 1;
  const endOpacity = 1;

  const enterTransition = {
    // duration: duration,
    // ease: 'easeInOut',
    type: 'spring',
    stiffness: 150,
    damping: 23,
    // delay: duration,
    // when: 'beforeChildren',
  };
  const exitTransition = {
    duration: duration / 2,
    ease: 'easeInOut',
  };

  const downVariant = {
    initial: {
      opacity: startOpacity,
      y: `-${distance}vh`,
    },
    enter: {
      opacity: endOpacity,
      y: 0,
      transition: enterTransition,
    },
    exit: {
      opacity: startOpacity,
      y: `${distance}vh`,
      transition: exitTransition,
    },
  };

  const upVariant = {
    initial: {
      opacity: startOpacity,
      y: `${distance}vh`,
    },
    enter: {
      opacity: endOpacity,
      y: 0,
      transition: enterTransition,
    },
    exit: {
      opacity: startOpacity,
      y: `-${distance}vh`,
      transition: exitTransition,
    },
  };

  const rightVariant = {
    initial: {
      opacity: startOpacity,
      x: `-${distance}vw`,
    },
    enter: {
      opacity: endOpacity,
      x: 0,
      transition: enterTransition,
    },
    exit: {
      opacity: startOpacity,
      x: `${distance}vw`,
      transition: exitTransition,
    },
  };

  const leftVariant = {
    initial: {
      opacity: startOpacity,
      x: `${distance}vw`,
    },
    enter: {
      opacity: endOpacity,
      x: 0,
      transition: enterTransition,
    },
    exit: {
      opacity: startOpacity,
      x: `-${distance}vw`,
      transition: exitTransition,
    },
  };

  let variant = downVariant;

  switch (animationMode) {
    case ANIMATION_MODE.RIGHT:
      variant = rightVariant;
      break;
    case ANIMATION_MODE.LEFT:
      variant = leftVariant;
      break;
    case ANIMATION_MODE.UP:
      variant = upVariant;
      break;
    case ANIMATION_MODE.DOWN:
      variant = downVariant;
      break;
    default:
      variant = downVariant;
  }

  // reset variant
  // useEffect(() => {
  //   setAnimationMode(ANIMATION_MODE.DOWN);
  // });

  const onComplete = function () {
    setAnimationMode(ANIMATION_MODE.UP);
  };

  return (
    <AnimatePresence>
      <motion.div
        key={location.pathname}
        variants={variant}
        initial="initial"
        animate="enter"
        exit="exit"
        onAnimationComplete={onComplete}
      >
        {children}
      </motion.div>
    </AnimatePresence>
  );
};

export default Transition;
