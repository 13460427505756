import { useMemo } from 'react';

import blobImage from '../components/common/Blobs/src/rave.jpg';

export const useSplitContent = blocks => {
  return useMemo(() => {
    const textBlocks = [];
    let hadMedia = false;

    for (let i = 0; i < blocks.length; i++) {
      if (hadMedia) {
        break;
      } else {
        if (blocks[i]._rawTextArea?.length > 0) {
          textBlocks.push(blocks[i]._rawTextArea);
        } else {
          hadMedia = true;
        }
      }
    }

    return [textBlocks, blocks.slice(textBlocks.length)];
  }, [blocks]);
};

export const blocksToContent = pageBlocks => {
  const blocks = [];

  pageBlocks.forEach(block => {
    if (block._rawTextArea) {
      blocks.push({
        sanityContent: block._rawTextArea,
        full: true,
      });
    }

    if (block.imageText?.imageTextBlock) {
      block.imageText.imageTextBlock.forEach(imageBlock => {
        blocks.push({
          image: imageBlock?.image?.image?.asset?.fluid,
          imageObject: imageBlock?.image?.image,
          imageAltText: imageBlock?.image?.image?.altText,
          content: imageBlock?.description,
          isPortrait: imageBlock?.image?.image?.asset?.fluid.aspectRatio < 1,
        });
      });
    }

    if (block.mediaText?.mediaTextBlock) {
      block.mediaText.mediaTextBlock.forEach(mediaBlock => {
        blocks.push({
          mediaUrl: mediaBlock?.media.mediaUrl,
          content: mediaBlock?.description,
        });
      });
    }
  });

  return blocks;
};

export const courseImage = courses => {
  if (!courses || courses.length === 0) {
    return blobImage;
  } else {
    const randomCourse = courses[Math.floor(Math.random() * courses.length)];
    if (!randomCourse?.node?.blobImages || randomCourse?.node?.blobImages?.length === 0) {
      return blobImage;
    }

    const image =
      randomCourse.node.blobImages[Math.floor(Math.random() * randomCourse.node.blobImages.length)].image?.asset?.fluid
        ?.src;
    if (!image) {
      return blobImage;
    } else {
      return image;
    }
  }
};
