import React, { useRef, useEffect } from 'react';
import classNames from 'classnames';

import { clickOutside } from '../../../utils/common';
import styles from './StudentGrid.module.scss';

const StudentGridFilter = ({ open, children, onClose }) => {
  const cls = classNames(styles.dropdownContent, {
    [styles.open]: open,
  });

  const ref = useRef(null);

  useEffect(() => {
    if (open) {
      return clickOutside(ref.current, () => {
        // onClose();
      });
    }
  }, [open, onClose]);

  return (
    <div ref={ref} className={cls}>
      {children}
    </div>
  );
};

export { StudentGridFilter };
