import React from 'react'; // eslint-disable-line no-unused-vars

const ShapeDiver = ({ urlUri, onReady }) => {
  const styles = {
    overflowX: 'hidden',
    overflowY: 'hidden',
    borderWidth: '0',
  };
  return (
    <div className="rul-embed-platform shapediver">
      <iframe
        title={urlUri}
        id="sdv-iframe"
        width="100%"
        height="480px"
        src={`${urlUri}&brandedMode=false`}
        referrerPolicy="origin"
        allowFullScreen
        scrolling="no"
        style={styles}
        ref={() => onReady()}
      >
        <p>Your browser does not support iframes.</p>
      </iframe>
    </div>
  );
};

export default ShapeDiver;
