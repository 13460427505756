import React from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import { motion, AnimatePresence } from 'framer-motion';

import { BigLink } from '../BigLink/BigLink';
import styles from './BigPageMenuItem.module.scss';

const BigPageMenuItem = ({ label, subItems, open, onToggle, whiteOutline }) => {
  const cls = classNames(
    whiteOutline && styles.giantMenuContainer,
    !whiteOutline && styles.giantMenuContainerBlack,
    styles.menuList,
    {
      [styles.active]: open,
    },
  );

  return (
    <div className={cls}>
      <BigLink className={styles.link} onClick={() => onToggle()} stroke>
        {label}
      </BigLink>
      <AnimatePresence initial={false}>
        {open && (
          <motion.ul
            key="modal"
            initial={{ height: 0, opacity: 0 }}
            animate={{ height: 'auto', opacity: 1 }}
            exit={{ height: 0, opacity: 0 }}
            className={styles.menuList}
          >
            {subItems.map(({ to, label }) => (
              <li key={to}>
                <BigLink className={styles.link} size="small" to={to}>
                  {label}
                </BigLink>
              </li>
            ))}
          </motion.ul>
        )}
      </AnimatePresence>
    </div>
  );
};

export const BigPageMenuItemProps = {
  label: PropTypes.string.isRequired,
  subItems: PropTypes.arrayOf(
    PropTypes.shape({
      to: PropTypes.string.isRequired,
      label: PropTypes.string.isRequired,
    }),
  ),
};

BigPageMenuItem.propTypes = {
  ...BigPageMenuItemProps,
  open: PropTypes.bool,
  onToggle: PropTypes.func,
};

export default BigPageMenuItem;
