import React from 'react'; // eslint-disable-line no-unused-vars
import { graphql } from 'gatsby';
import { Link } from 'gatsby';
import SEO from '../components/common/seo';
import { PageSection } from '../components/common/PageSection/PageSection';
import { Hero } from '../components/layout/Hero/Hero';

export const query = graphql`
  query TagPageQuery {
    tags: allSanityTag {
      edges {
        node {
          tagName
          slug {
            current
          }
        }
      }
    }
  }
`;

const tagPage = ({ data }) => {
  const tags = data.tags.edges;

  const pageDescription = 'Description for SEO about Ravensbourne tags';

  return (
    <>
      <SEO title="Tags" description={pageDescription} />

      <Hero>
        <h1>Tags</h1>
      </Hero>

      {tags?.length > 0 && (
        <PageSection>
          {tags.map((tag, index) => {
            return (
              <div key={`tag-${tag.node.tagName}`}>
                <Link to={`/tags/${tag.node.slug.current}`}>{tag.node.tagName}</Link>
              </div>
            );
          })}
        </PageSection>
      )}
    </>
  );
};

export default tagPage;
