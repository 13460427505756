import React, { createContext, useContext, useState, useEffect } from 'react';

const RavensbourneContext = createContext({
  searchOpen: false,
  setSearchOpen: () => {},
  menuOpen: false,
  setMenuOpen: () => {},
});

export const ANIMATION_MODE = {
  UP: 'up',
  DOWN: 'down',
  LEFT: 'left',
  RIGHT: 'right',
};

const RavensbourneContextContainer = ({ children }) => {
  const [searchOpen, setSearchOpen] = useState(false);
  const [menuOpen, setMenuOpen] = useState(false);
  const [searchQuery, setSearchQuery] = useState(``);
  const [animationMode, setAnimationMode] = useState(ANIMATION_MODE.UP);
  const [swUpdated, setSwUpdated] = useState(false);

  useEffect(() => {
    window.addEventListener('sw-updated', () => {
      setSwUpdated(true);
    });
  }, []);

  const value = {
    searchOpen,
    setSearchOpen,
    menuOpen,
    setMenuOpen,
    searchQuery,
    setSearchQuery,
    animationMode,
    setAnimationMode,
    swUpdated,
  };

  return <RavensbourneContext.Provider value={value}>{children}</RavensbourneContext.Provider>;
};

export const useRavensbourneContext = () => useContext(RavensbourneContext);
export { RavensbourneContextContainer };
