import React from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import { RULImage } from '../../common/RULImage/RULImage';
import { RULEmbed } from '../../common/RULEmbed/RULEmbed';
import styles from './PageBlock.module.scss';

const PageBlock = ({
  children,
  image,
  imageObject,
  imageAltText = '',
  isPortrait,
  mediaUrl,
  className,
  full = false,
}) => {
  const cls = classNames(
    styles.block,
    {
      [styles.full]: full,
    },
    {
      [styles.isPortrait]: isPortrait,
    },
    className,
  );
  return (
    <div className={cls}>
      <div className={styles.media}>
        {mediaUrl && <RULEmbed mediaUrl={mediaUrl} />}
        {/* {image && <Img fluid={image} alt={imageAltText} />} */}
        {image && <RULImage image={imageObject} alt={imageAltText} />}
      </div>
      <div className={styles.text}>{children}</div>
    </div>
  );
};

PageBlock.propTypes = {
  children: PropTypes.node,
  images: PropTypes.object,
  mediaUrl: PropTypes.string,
  className: PropTypes.string,
  full: PropTypes.bool,
};

export { PageBlock };
