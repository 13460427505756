import React, { useState, useMemo, useEffect, useCallback } from 'react';
import imageUrlBuilder from '@sanity/image-url';
import sanityClient from '@sanity/client';
import { motion, AnimatePresence } from 'framer-motion';
import { shuffle } from '../../../utils/common';
import { Icon } from '../../common/Icon/Icon';
import { useSpecialCursor } from '../../common/SpecialCursor/SpecialCursor';
import { HomeBlockGridItem } from './HomeBlockGridItem';

import styles from './RandomStudentFeatureSection.module.scss';

const client = sanityClient({
  projectId: '8yqkya4x',
  dataset: 'rul',
  useCdn: true,
});
const builder = imageUrlBuilder(client);

const urlFor = source => {
  return builder.image(source);
};

const RandomStudentFeatureSection = ({ students, features }) => {
  const [selectionIndex, setselectionIndex] = useState(0);
  const [numberStudents] = useState(students.length);
  const [fourStudents, setFourStudents] = useState([]);

  const [featureSelectionIndex, setFeatureSelectionIndex] = useState(0);
  const [numberFeatures] = useState(features.length);
  const [feature, setFeature] = useState({});

  const [normalDirection, setNormalDirection] = useState(true);
  const { handlers } = useSpecialCursor();

  const shuffledStudents = useMemo(() => {
    return shuffle(students);
  }, [students]);

  const shuffledFeatures = useMemo(() => {
    return shuffle(features);
  }, [features]);

  useEffect(() => {
    const items = [];

    let i = selectionIndex * 4;
    let y;

    for (y = 0; i < selectionIndex * 4 + 4; i++, y++) {
      items[y] = {};
      items[y]._id = shuffledStudents[i].node._id;
      items[y].displayName = shuffledStudents[i].node.displayName;
      items[y].slug = shuffledStudents[i].node.slug;
      items[y].featuredImage = shuffledStudents[i].node.featuredImage;
      items[y].courseName = shuffledStudents[i].node.courseRef.courseName;
    }

    setFourStudents(items);
    const item = {};

    item.displayTitle = shuffledFeatures[featureSelectionIndex].node.displayTitle;
    item.slug = shuffledFeatures[featureSelectionIndex].node.slug;
    item.featuredImage = shuffledFeatures[featureSelectionIndex].node.coverImage;
    item.courseName = shuffledFeatures[featureSelectionIndex].node.courseRef?.courseName;
    setFeature(item);

    const timer = setTimeout(() => {
      if (selectionIndex * 4 > numberStudents - 8) {
        setselectionIndex(0);
      } else {
        setselectionIndex(selectionIndex + 1);
      }

      if (featureSelectionIndex > numberFeatures - 2) {
        setFeatureSelectionIndex(0);
      } else {
        setFeatureSelectionIndex(featureSelectionIndex + 1);
      }
    }, 8000);

    return () => {
      clearTimeout(timer);
    };
  }, [shuffledFeatures, featureSelectionIndex]);

  // Next Feature and Set of Students
  const nextSet = useCallback(() => {
    if (selectionIndex * 4 > numberStudents - 8) {
      setselectionIndex(0);
    } else {
      setselectionIndex(selectionIndex + 1);
    }

    if (featureSelectionIndex > numberFeatures - 2) {
      setFeatureSelectionIndex(0);
    } else {
      setFeatureSelectionIndex(featureSelectionIndex + 1);
    }
    // set the animation direction
    setNormalDirection(true);
  }, [selectionIndex, numberStudents, featureSelectionIndex, numberFeatures, setNormalDirection]);

  // Previous Feature and Set of Students
  const prevSet = useCallback(() => {
    if (selectionIndex * 4 < 0 + 4) {
      setselectionIndex(Math.floor((numberStudents - 4) / 4));
    } else {
      setselectionIndex(selectionIndex - 1);
    }

    if (featureSelectionIndex === 0) {
      setFeatureSelectionIndex(numberFeatures - 1);
    } else {
      setFeatureSelectionIndex(featureSelectionIndex - 1);
    }
    // set the animation direction
    setNormalDirection(false);
  }, [selectionIndex, numberStudents, featureSelectionIndex, numberFeatures, setNormalDirection]);

  return (
    <>
      <div className={styles.cardsContainer}>
        <AnimatePresence initial={false}>
          <motion.div
            key={`modal-${selectionIndex}`}
            initial={{ opacity: 0 }}
            animate={{ opacity: 1 }}
            exit={{ opacity: 0 }}
            // transition={{
            //   duration: 0.5,
            // }}
            className={styles.cardsInside}
          >
            <div className={styles.cardsGrid}>
              <div className={styles.studentsGrid}>
                {fourStudents.map((student, i) => {
                  return (
                    <motion.div
                      key={`card-${selectionIndex}-${i}-${student.displayName}`}
                      initial={{
                        opacity: 0,
                        x: normalDirection ? '-100%' : '100%',
                        // scale: 0,
                      }}
                      animate={{
                        opacity: 1,
                        x: 0,
                        // scale: 1,
                      }}
                      exit={{
                        opacity: 0,
                        x: normalDirection ? '100%' : '-100%',
                        // scale: 0,
                      }}
                      transition={{
                        type: 'spring',
                        stiffness: 150,
                        damping: 23,
                        delay: i * 0.1,
                      }}
                      // transition={{
                      //   // scale: { duration: 1.5 },
                      //   duration: 0.7,
                      //   ease: 'easeInOut',
                      //   opacity: {
                      //     delay: 0.15,
                      //   },
                      //   staggerChildren: 0.5,
                      // }}
                    >
                      <HomeBlockGridItem
                        large={true}
                        to={`/students/${student.slug?.current}`}
                        image={false}
                        imageSrc={urlFor(student.featuredImage?.image).width(350).height(350).url()}
                        title={`${student.displayName}`}
                        course={student.courseName}
                        imageAlt={
                          student.featuredImage?.image?.altText
                            ? student.featuredImage?.image?.altText
                            : student.displayName
                        }
                        className={('paragraph', 'heading')}
                      />
                    </motion.div>
                  );
                })}
              </div>
              <div className={styles.projectFeature}>
                <motion.div
                  key={`card-feature-${selectionIndex}-${feature.displayTitle}`}
                  initial={{
                    opacity: 0,
                    x: normalDirection ? '-100%' : '100%',
                    // scale: 0,
                  }}
                  animate={{
                    opacity: 1,
                    x: 0,
                    // scale: 1,
                  }}
                  exit={{
                    opacity: 0,
                    x: normalDirection ? '100%' : '-100%',
                    // scale: 0,
                  }}
                  transition={{
                    type: 'spring',
                    stiffness: 150,
                    damping: 23,
                    delay: 0.1,
                  }}
                  // transition={{
                  //   // scale: { duration: 1.5 },
                  //   duration: 0.7,
                  //   ease: 'easeInOut',
                  //   // opacity: {
                  //   //   delay: 0.15,
                  //   // },
                  // }}
                >
                  <HomeBlockGridItem
                    large={false}
                    to={`/features/${feature.slug?.current}`}
                    image={false}
                    imageSrc={urlFor(feature.featuredImage?.image).width(700).height(700).url()}
                    title={`${feature.displayTitle}`}
                    course={feature.courseName}
                    imageAlt={
                      feature.featuredImage?.image.altText ? feature.featuredImage?.image.altText : feature.displayTitle
                    }
                  />
                </motion.div>
              </div>
            </div>
          </motion.div>
        </AnimatePresence>
      </div>
      <div className={styles.controls}>
        <button aria-label="Previous" onClick={() => prevSet()} className={styles.arrow} {...handlers}>
          <Icon label="Previous" className={styles.breadcrumbIcon} name="thin-arrow" />
        </button>
        <button aria-label="Next" onClick={() => nextSet()} className={styles.arrow} {...handlers}>
          <Icon label="Next" className={styles.breadcrumbIcon} name="thin-arrow" flip />
        </button>
      </div>
    </>
  );
};

export default RandomStudentFeatureSection;
