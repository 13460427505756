import React from 'react';
import classNames from 'classnames';
import { Link } from 'gatsby';
// import { motion } from 'framer-motion';

import { useSpecialCursor } from '../../common/SpecialCursor/SpecialCursor';
import styles from './HomeBlockGrid.module.scss';

// const variants = {
//   initial: { opacity: 1, scale: 1 },
//   exit: { opacity: 0, scale: 0.4 },
//   enter: { opacity: 1, scale: 1 },
// };

const HomeBlockGridItem = ({ large, imageAlt, imageSrc, title, course, to }) => {
  const { handlers } = useSpecialCursor();

  const cls = classNames(
    {
      [styles.noImage]: !imageSrc,
    },
    styles.itemContainer,
  );

  return (
    <div className={cls}>
      <Link className={`${styles.link} paragraph heading`} to={to} {...handlers}>
        {imageSrc && <img src={imageSrc} alt={imageAlt} />}
        <span className={styles.overlay}>
          <span>
            {title}
            <br />
          </span>
          <span className={styles.courseName}>{course}</span>
        </span>
      </Link>
    </div>
  );
};

export { HomeBlockGridItem };
