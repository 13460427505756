import React from 'react'; // eslint-disable-line no-unused-vars
import { graphql } from 'gatsby';
import SEO from '../components/common/seo';
import StudentGrid from '../components/pageComponents/Course/StudentGrid';
import { PageSection } from '../components/common/PageSection/PageSection';
import { Hero } from '../components/layout/Hero/Hero';
import styles from './tag.module.scss';

export const query = graphql`
  query TagTemplateQuery($slug: String!) {
    tag: sanityTag(slug: { current: { eq: $slug } }) {
      tagName
      slug {
        current
      }
      id
      tagName
      slug {
        current
      }
      id
    }

    featureProjects: allSanityFeatureProject(filter: { tags: { elemMatch: { slug: { current: { eq: $slug } } } } }) {
      edges {
        node {
          slug {
            current
          }
          featureProjectName
          displayTitle
          courseRef {
            courseName
          }
          coverImage {
            altText
            image {
              asset {
                fluid(maxWidth: 960) {
                  ...GatsbySanityImageFluid
                }
              }
            }
          }
          tags {
            tagName
            slug {
              current
            }
          }
        }
      }
    }
    students: allSanityRulStudent(
      filter: { tags: { elemMatch: { slug: { current: { eq: $slug } } } } }
      sort: { fields: surname, order: ASC }
    ) {
      edges {
        node {
          displayName
          featuredImage {
            altText
            image {
              asset {
                fluid(maxWidth: 400) {
                  ...GatsbySanityImageFluid
                }
              }
            }
          }
          courseRef {
            courseName
          }
          slug {
            current
          }
          tags {
            tagName
            slug {
              current
            }
          }
        }
      }
    }
  }
`;

const TagTemplate = ({ data }) => {
  const tag = data.tag;
  const seoDescription = tag.tagName || '';

  const students = data.students.edges;
  const featureProjects = data.featureProjects.edges.map(({ node }) => node);

  return (
    <>
      <SEO title={tag.tagName} description={seoDescription} />

      <Hero light half contentClassName={styles.hero}>
        <h1 className={`header3`}>
          <span className={styles.stroke}>Tags:</span>
          <span className={'no-stroke'}>{tag.tagName}</span>
        </h1>
      </Hero>

      {students?.length > 0 && (
        <PageSection dark>
          <StudentGrid students={students} featureProjects={featureProjects} specialism={false} />
        </PageSection>
      )}
    </>
  );
};

export default TagTemplate;
