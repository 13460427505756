import React from 'react'; // eslint-disable-line no-unused-vars
import ReactPlayer from 'react-player';

// For Platform options see:
// https://www.npmjs.com/package/react-player

const Youtube = ({ urlUri, onReady }) => {
  return (
    <div
      className="rul-embed-platform youtube"
      style={{
        position: 'relative',
        paddingTop: '56.25%',
      }}
    >
      <ReactPlayer
        url={urlUri}
        config={{
          youtube: {
            playerVars: { showinfo: 1, controls: true },
            embedOptions: {},
          },
        }}
        onReady={onReady}
        playing={false}
        width="100%"
        height="100%"
        style={{
          position: 'absolute',
          top: 0,
          left: 0,
        }}
      />
    </div>
  );
};

export default Youtube;
