import React, { useMemo } from 'react'; // eslint-disable-line no-unused-vars
import SanityBlockContent from '@sanity/block-content-to-react';
import { LinkList } from '../../layout/LinkList/LinkList';
import { MainTextContent } from '../../layout/MainTextContent/MainTextContent';

const CourseAbout = ({ title, introText, websiteLink, socialLinks }) => {
  const links = useMemo(() => {
    const arr = [];

    if (socialLinks.length) {
      socialLinks.forEach(link => arr.push({ to: link.url, label: link.socialPlatform }));
    }

    if (websiteLink && websiteLink.url) {
      arr.push({
        to: websiteLink.url,
        label: 'Website',
      });
    }

    return arr;
  }, [socialLinks, websiteLink]);

  return (
    <>
      <MainTextContent
        dark={false}
        title={title}
        rightSlot={<>{links.length > 0 && <LinkList title="Links" links={links} />}</>}
      >
        <SanityBlockContent blocks={introText} />
      </MainTextContent>
    </>
  );
};

export { CourseAbout };
