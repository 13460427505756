import React from 'react';
import { graphql } from 'gatsby';
import SEO from '../components/common/seo';
import { PostPage } from '../components/pageComponents/Post/PostPage';
import { Hero } from '../components/layout/Hero/Hero';
import { Blobs } from '../components/common/Blobs/Blobs';
import { courseImage } from '../utils/content';

export const query = graphql`
  query PostTemplateQuery($slug: String!) {
    courses: allSanityCourse {
      edges {
        node {
          blobImages {
            image {
              asset {
                fluid {
                  src
                }
              }
            }
          }
        }
      }
    }
    post: sanityPost(slug: { current: { eq: $slug } }) {
      displayTitle
      description
      breakSubtitle
      showBlobImages
      selectedBlobs
      _rawIntroduction(resolveReferences: { maxDepth: 10 })
      coverImage {
        altText
        image {
          asset {
            fluid(maxWidth: 1920) {
              ...GatsbySanityImageFluid
              aspectRatio
            }
          }
        }
      }
      pageBlocks {
        _rawTextArea(resolveReferences: { maxDepth: 10 })
        imageText {
          imageTextBlock {
            description
            image {
              altText
              image {
                asset {
                  extension
                  url
                  metadata {
                    dimensions {
                      aspectRatio
                    }
                  }
                  fluid(maxWidth: 1920) {
                    ...GatsbySanityImageFluid
                    aspectRatio
                  }
                }
              }
            }
          }
        }
        mediaText {
          mediaTextBlock {
            description
            media {
              mediaUrl
            }
          }
        }
      }
    }
  }
`;

const PostTemplate = ({ data }) => {
  const post = data.post;
  const courses = data.courses.edges;
  const seoDescription = post.description?.substr(0, 140) || '';
  const seoImage = post.coverImage?.image?.asset?.fluid?.src;

  const image = post.showBlobImages ? courseImage(courses) : false;
  const selected = post.selectedBlobs ? true : false;

  const breakName = post.breakSubtitle.replace(/\\n/g, '\n');
  return (
    <>
      <SEO title={post.displayTitle} description={seoDescription} image={seoImage} />

      {/* <HeaderPad /> */}
      <Hero>
        <Blobs title={breakName} image={image} selected={selected} />
      </Hero>

      <PostPage {...post} />
    </>
  );
};

export default PostTemplate;
