import React from 'react';

import { PageSection } from '../../common/PageSection/PageSection';
import { MainTextContent } from '../../layout/MainTextContent/MainTextContent';
import styles from './Contact.module.scss';

const Thankyou = () => {
  return (
    <>
      <PageSection>
        <MainTextContent dark={false} title="Thank you">
          <div className={styles.contactForm}>Your message has been sent.</div>
        </MainTextContent>
      </PageSection>
    </>
  );
};
export default Thankyou;
