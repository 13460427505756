import React from 'react';
// import ReCAPTCHA from 'react-google-recaptcha';
import { PageSection } from '../../common/PageSection/PageSection';
import { MainTextContent } from '../../layout/MainTextContent/MainTextContent';
import styles from './Contact.module.scss';

const ContactForm = () => {
  return (
    <>
      <PageSection>
        <MainTextContent dark={false} title="Contact us">
          <div className={styles.contactForm}>
            <form
              method="POST"
              netlify-honeypot="bot-field"
              data-netlify="true"
              // data-netlify-recaptcha="true"
              name="contact"
              action="/thank-you"
            >
              <input type="hidden" name="bot-field" />
              <input type="hidden" name="form-name" value="contact" />
              <div className={`${styles.formSection}`}>
                <label>
                  <div>
                    <input type="text" name="name" id="name" placeholder="Your name" />
                  </div>
                  <div className={styles.labelText}>Name (required)</div>
                </label>
              </div>
              <div className={styles.formSection}>
                <label>
                  <div>
                    <input type="email" name="email" id="email" placeholder="Your email" />
                  </div>
                  <div className={styles.labelText}>Email (required)</div>
                </label>
              </div>
              <div className={styles.formSection}>
                <label>
                  <div>
                    <input type="text" name="subject" id="subject" placeholder="Email subject" />
                  </div>
                  <div className={styles.labelText}>Subject (required)</div>
                </label>
              </div>
              <div className={styles.formSection}>
                <label>
                  <div>
                    <textarea name="message" id="message" rows="10" placeholder="Your message" />
                  </div>
                  <div className={styles.labelText}>Message</div>
                </label>
              </div>
              <div>
                {/* <ReCAPTCHA sitekey={process.env.GATSBY_RECAPTCHA_KEY} /> */}
                <button className={styles.button} type="submit">
                  Submit
                </button>
                {/* <input type="reset" value="Clear" /> */}
              </div>
            </form>
          </div>
        </MainTextContent>
      </PageSection>
    </>
  );
};
export default ContactForm;
