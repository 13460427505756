import React from 'react'; // eslint-disable-line no-unused-vars
import InstagramEmbed from 'react-instagram-embed';

const Instagram = ({ urlUri, onReady }) => {
  return (
    <div className="rul-embed-platform instagram">
      <InstagramEmbed
        url={urlUri}
        maxWidth={'100%'}
        hideCaption={false}
        containerTagName="div"
        protocol=""
        injectScript
        onLoading={() => {}}
        onSuccess={() => onReady()}
        onAfterRender={() => {}}
        onFailure={() => {}}
      />
    </div>
  );
};

export default Instagram;
