import React, { useMemo } from 'react';
import SanityBlockContent from '@sanity/block-content-to-react';

import { Breadcrumb } from '../../layout/Breadcrumb/Breadcrumb';
import { PageSection } from '../../common/PageSection/PageSection';
import { MainTextContent } from '../../layout/MainTextContent/MainTextContent';
import { LinkList } from '../../layout/LinkList/LinkList';
import { PageBlock } from '../../layout/PageBlocks/PageBlock';
import { CoverImage } from '../../layout/CoverImage/CoverImage';
import { blocksToContent } from '../../../utils/content';
import { ANIMATION_MODE } from '../../context/RavensbourneContext';

const FeaturePage = ({
  description,
  coverImage,
  displayTitle,
  courseRef,
  tags,
  featureType,
  pageBlocks,
  _rawIntroduction,
}) => {
  const displayBreadcrumb = courseRef?.slug ? true : false;
  const courseLink = `/courses/${courseRef?.slug?.current}`;
  const courseTitle = `${courseRef?.courseType ? `${courseRef?.courseType} ` : ''} ${courseRef?.courseName}`;

  const tagLinks = useMemo(() => {
    return tags.map(tag => {
      return {
        to: `/tags/${tag.slug.current}`,
        label: tag.tagName,
      };
    });
  }, [tags]);

  /** turn the internal array of blocks into one flat array to iterate over */
  const splitBlocks = useMemo(() => {
    return blocksToContent(pageBlocks);
  }, [pageBlocks]);

  return (
    <>
      {displayBreadcrumb && (
        <PageSection>
          <Breadcrumb slug={courseLink} title={courseTitle} direction={ANIMATION_MODE.RIGHT} light />
        </PageSection>
      )}
      <CoverImage image={coverImage.image.asset.fluid} alt={coverImage.altText} />

      <PageSection dark>
        <MainTextContent
          title={displayTitle}
          subtitle={featureType?.categoryName}
          rightSlot={<>{tagLinks.length > 0 && <LinkList title="Tags" links={tagLinks} />}</>}
        >
          <SanityBlockContent blocks={_rawIntroduction} />
        </MainTextContent>
      </PageSection>

      <PageSection padY>
        {splitBlocks.map(({ content, sanityContent, ...rest }, idx) => (
          <PageBlock key={idx} {...rest}>
            {content && <div>{content}</div>}
            {sanityContent && <SanityBlockContent blocks={sanityContent} />}
          </PageBlock>
        ))}
      </PageSection>
      {displayBreadcrumb && (
        <PageSection dark>
          <Breadcrumb slug={courseLink} title={courseTitle} direction={ANIMATION_MODE.RIGHT} />
        </PageSection>
      )}
    </>
  );
};

export { FeaturePage };
