import React from 'react'; // eslint-disable-line no-unused-vars
import ReactPlayer from 'react-player';

// For Platform options see:
// https://www.npmjs.com/package/react-player

const DailyMotion = ({ urlUri, onReady }) => {
  return (
    <div className="rul-embed-platform dailymotion" style={{ position: 'relative', paddingTop: '56.25%' }}>
      <ReactPlayer
        url={urlUri}
        config={{
          dailymotion: {
            params: {
              'ui-theme': 'light',
            },
          },
        }}
        controls
        playing={false}
        width="100%"
        height="100%"
        style={{ position: 'absolute', top: 0, left: 0 }}
        onReady={onReady}
      />
    </div>
  );
};

export default DailyMotion;
