import React from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import { PageSection } from '../../common/PageSection/PageSection';
import { InternalExternalLink } from '../../common/InternalExternalLink/InternalExternalLink';

import styles from './Footer.module.scss';

const Footer = ({ alternate }) => {
  const cls = classNames(styles.footer, {
    [styles.alternate]: alternate,
  });

  return (
    <footer className={cls}>
      <PageSection dark={!alternate} className={styles.inner}>
        <div className={styles.column}>
          <div className={classNames(styles.columnHeader, 'header5')}>Contact</div>
          <p>
            Ravensbourne
            <br />
            University London
            <br />
            6 Penrose Way
            <br />
            Greenwich Peninsula
            <br />
            London
            <br />
            SE10 0EW
          </p>
          <p>
            <a href="tel:+44 (0) 20 3040 3500">+44 (0) 20 3040 3500</a>
          </p>
          <p>
            <a href="mailto:info@rave.ac.uk">info@rave.ac.uk</a>
          </p>
        </div>

        <div className={styles.column}>
          <div className={classNames(styles.columnHeader, 'header5')}>About</div>
          <p>
            Design by Ravensbourne's students and{' '}
            <InternalExternalLink to="https://www.ravensbourne.ac.uk/institute-creativity-and-technology/creativelab">
              CreativeLab
            </InternalExternalLink>{' '}
          </p>
          <p>The Making of the Degree Show:</p>
          <p>
            <InternalExternalLink to="/about/the-making-of-the-degree-show-2020-the-concept">
              The Concept
            </InternalExternalLink>
          </p>
          <p>
            <InternalExternalLink to="/about/the-making-of-the-degree-show-2020-concept-development">
              Concept Development
            </InternalExternalLink>
          </p>
          <p>
            <InternalExternalLink to="/about/the-making-of-the-degree-show-2020-concept-realisation">
              Concept Realisation
            </InternalExternalLink>
          </p>
          <p>
            <InternalExternalLink to="https://www.mtj.io/">Website by MTJ.io</InternalExternalLink>
          </p>
        </div>

        <div className={styles.column}>
          <div className={classNames(styles.columnHeader, 'header5')}>Legal</div>
          <p>
            <InternalExternalLink to="https://www.ravensbourne.ac.uk/privacy-and-cookies">
              Privacy and cookies
            </InternalExternalLink>
          </p>
          <p>
            <InternalExternalLink to="https://www.ravensbourne.ac.uk/terms-and-conditions">
              Terms and conditions
            </InternalExternalLink>
          </p>
        </div>
        <div className={styles.column}>
          <div className={classNames(styles.columnHeader, 'header5')}>Connect</div>
          <p>
            <InternalExternalLink to="/contact/">Contact</InternalExternalLink>
          </p>
          <p>
            <InternalExternalLink to="https://www.instagram.com/ravensbourneuk/">Instagram</InternalExternalLink>
          </p>
          <p>
            <InternalExternalLink to="https://twitter.com/RavensbourneUK">Twitter</InternalExternalLink>
          </p>
        </div>
      </PageSection>
    </footer>
  );
};

Footer.propTypes = {
  alternate: PropTypes.bool,
};

export default Footer;
