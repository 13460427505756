import React from 'react';
import SEO from '../components/common/seo';
import ContactForm from '../components/pageComponents/Contact/Contact';
import { HeaderPad } from '../components/layout/Header/Header';

const ContactUs = () => {
  return (
    <>
      <SEO title="Contact Us" />
      <HeaderPad />
      <ContactForm />
    </>
  );
};
export default ContactUs;
